import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";

export const BusinessApi = createApi({
    reducerPath: "business",
    baseQuery: createCustomFetchBaseQuery(),
    endpoints: (builder) => ({
        getAllBusinesses: builder.query({
            query: ({ pageno, search }) => ({
                url: `api/admin/businesses?pageno=${pageno + 1}&search=${search}`,
                method: "GET",
                transformResponse: (res) => res,
            }),
        }),

        addNewBusiness: builder.mutation({
            query: (newBusiness) => (
                {
                    url: `api/admin/business/add`,
                    method: "POST",
                    body: newBusiness,
                }),
        }),

        getBusinessById: builder.query({
            query: (id) => `api/admin/business/${id}`,
        }),

        updateBusinessStatus: builder.mutation({
            query: (id) => ({
                url: `api/admin/business/${id}`,
                method: "PUT",
            }),
        }),
    }),
});

export const {
    useGetAllBusinessesQuery,
    useAddNewBusinessMutation,
    useGetBusinessByIdQuery,
    useUpdateBusinessStatusMutation,
} = BusinessApi;