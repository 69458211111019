import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";


export const DashboardApi = createApi({
    reducerPath: "dashboard",
    baseQuery: createCustomFetchBaseQuery(),
    endpoints: (builder) => ({
        getDashboardData: builder.query({
            query: () => ({
                url: `api/admin/dashboard`,
                method: "GET",
                transformResponse: (res) => res,
            }),
        }),
    }),
});

export const { useGetDashboardDataQuery } = DashboardApi;