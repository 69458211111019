import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "./Reducer/auth";
import { BusinessApi } from "./Reducer/business";
import userReducer from "./slices/userSlice";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { FaqsApi } from "./Reducer/faqs";
import { SettingsApi } from "./Reducer/settings";
import { currencyApi } from "./Reducer/currency";
import { supportApi } from "./Reducer/support";
import { TransferApi } from "./Reducer/transfer";
import { customersApi } from "./Reducer/customer";
import { DashboardApi } from "./Reducer/dashboard";

// Define the persist configuration
const persistConfig = {
  key: 'root',
  storage,
};

// Create a persisted reducer for the user slice
const persistedUserReducer = persistReducer(persistConfig, userReducer);

// Create the Redux store with the persisted reducer for the user slice
export const store = configureStore({
  reducer: {
    [customersApi.reducerPath]: customersApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [BusinessApi.reducerPath]: BusinessApi.reducer,
    [FaqsApi.reducerPath]: FaqsApi.reducer,
    [SettingsApi.reducerPath]: SettingsApi.reducer,
    [currencyApi.reducerPath]: currencyApi.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    [TransferApi.reducerPath]: TransferApi.reducer,
    [DashboardApi.reducerPath]: DashboardApi.reducer,
    user: persistedUserReducer, // Use the persisted user reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      // productsApi.middleware,
      customersApi.middleware,
      authApi.middleware,
      BusinessApi.middleware,
      FaqsApi.middleware,
      SettingsApi.middleware,
      currencyApi.middleware,
      supportApi.middleware,
      TransferApi.middleware,
      DashboardApi.middleware,
    ),
});

// Initialize Redux Persist
export const persistor = persistStore(store);

// Setup listeners
setupListeners(store.dispatch);
