// utils
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  ADMIN_DASHBOARD: '/admin',
};

// ----------------------------------------------------------------------

export const paths = {
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/hjxMnGUJCjY7pX8lQbS7kn/%5BPreview%5D-Minimal-Web.v5.4.0?type=design&node-id=0-1&mode=design&t=2fxnS70DuiTLGzND-0',

  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
      verify: `${ROOTS.AUTH}/jwt/verify`,
      newPassword: `${ROOTS.AUTH}/jwt/new-password`,
      forgotPassword: `${ROOTS.AUTH}/jwt/forgot-password`,
    },
  },

  adminDashboard: {
    root: `${ROOTS.ADMIN_DASHBOARD}/dashboard`,
    general: {
      app: `${ROOTS.ADMIN_DASHBOARD}/app`,
    },
    user: {
      root: `${ROOTS.ADMIN_DASHBOARD}/user`,
      new: `${ROOTS.ADMIN_DASHBOARD}/user/new`,
      list: `${ROOTS.ADMIN_DASHBOARD}/user/list`,
      cards: `${ROOTS.ADMIN_DASHBOARD}/user/cards`,
      profile: `${ROOTS.ADMIN_DASHBOARD}/user/profile`,
      account: `${ROOTS.ADMIN_DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.ADMIN_DASHBOARD}/user/${id}/edit`,
    
    },
    customer: {
      root: `${ROOTS.ADMIN_DASHBOARD}/customers`,
      new: `${ROOTS.ADMIN_DASHBOARD}/customer/new`,
      cards: `${ROOTS.ADMIN_DASHBOARD}/customer/cards`,
      profile: `${ROOTS.ADMIN_DASHBOARD}/profile`,
      account: (id) => `${ROOTS.ADMIN_DASHBOARD}/customers/${id}/account`,
      edit: (id) => `${ROOTS.ADMIN_DASHBOARD}/user/${id}/edit`,
    },
    deletedCustomers: {
      root: `${ROOTS.ADMIN_DASHBOARD}/deleted-customers`
    },
    business: {
      root: `${ROOTS.ADMIN_DASHBOARD}/business`,
      new: `${ROOTS.ADMIN_DASHBOARD}/business/new`,
      list: `${ROOTS.ADMIN_DASHBOARD}/business/list`,
      edit: (id) => `${ROOTS.ADMIN_DASHBOARD}/business/${id}/edit`,
    },
    transfer: {
      root: `${ROOTS.ADMIN_DASHBOARD}/transfer`,
      list: `${ROOTS.ADMIN_DASHBOARD}/transfer/list`,
    },
    exchangeRate: {
      root: `${ROOTS.ADMIN_DASHBOARD}/exchange-rate`,
    },
    faqs: {
      root: `${ROOTS.ADMIN_DASHBOARD}/faqs`,
    },
    aboutUs: {
      root: `${ROOTS.ADMIN_DASHBOARD}/about-us`,
    },
    privacyPolicy: {
      root: `${ROOTS.ADMIN_DASHBOARD}/privacy-policy`,
    },
    terms: {
      root: `${ROOTS.ADMIN_DASHBOARD}/terms`,
    },
    contactUs: {
      root: `${ROOTS.ADMIN_DASHBOARD}/contact-us`,
    },
  },

};
