import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import ExchangeRatePage from 'src/sections/exchangeRate/view/exchangeRatePage';


// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
// Customer
const CustomerProfilePage = lazy(() => import('src/pages/dashboard/customer/profile'));
const CustomerCardsPage = lazy(() => import('src/pages/dashboard/customer/cards'));
const CustomerListPage = lazy(() => import('src/pages/dashboard/customer/list'));
const DeletedCustomerListPage = lazy(() => import('src/pages/dashboard/deletedCustomers/list'));
const CustomerAccountPage = lazy(() => import('src/pages/dashboard/customer/customerAccount'));
// const CustomerCreatePage = lazy(() => import('src/pages/dashboard/customer/new'));
// const CustomerEditPage = lazy(() => import('src/pages/dashboard/customer/edit'));
// Business
// const BusinessProfilePage = lazy(() => import('src/pages/dashboard/business/profile'));
// const BusinessCardsPage = lazy(() => import('src/pages/dashboard/business/cards'));
const BusinessListPage = lazy(() => import('src/pages/dashboard/business/list'));
// const BusinessAccountPage = lazy(() => import('src/pages/dashboard/business/account'));
// const BusinessAccountDetailsPage = lazy(() => import('src/pages/dashboard/business/accDetails'));
// const BusinessCreatePage = lazy(() => import('src/pages/dashboard/business/new'));
// const BusinessEditPage = lazy(() => import('src/pages/dashboard/business/edit'));
// Faqs
const FaqPage = lazy(() => import('src/pages/dashboard/faqs'))


// About Us
// const AboutUsPage = lazy(() => import('./dashboard/aboutUs'))


const AboutUsPage = lazy(() => import('src/pages/dashboard/aboutUs'));
const PrivacyPage = lazy(() => import('src/pages/dashboard/privacy'));
const TermsPage = lazy(() => import('src/pages/dashboard/terms'));
const ContactPage = lazy(() => import('src/pages/contact-us'));

// Transfer
const TransferProfilePage = lazy(() => import('src/pages/dashboard/transfer/profile'));
// const TransferCardsPage = lazy(() => import('src/pages/dashboard/transfer/cards'));
const TransferListPage = lazy(() => import('src/pages/dashboard/transfer/list'));
// const TransferAccountPage = lazy(() => import('src/pages/dashboard/transfer/account'));
// const TransferCreatePage = lazy(() => import('src/pages/dashboard/transfer/new'));
// const TransferEditPage = lazy(() => import('src/pages/dashboard/transfer/edit'));

// ----------------------------------------------------------------------

export const admindashboardRoutes = [
  {
    path: 'admin',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'dashboard', element: <IndexPage />, index: true },
      // { path: 'ecommerce', element: <OverviewEcommercePage /> },
      // { path: 'analytics', element: <OverviewAnalyticsPage /> },
      // { path: 'banking', element: <OverviewBankingPage /> },
      // { path: 'booking', element: <OverviewBookingPage /> },
      // { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'profile', element: <CustomerProfilePage />, index: true
      },
      {
        path: 'faqs', element: <FaqPage />, index: true
      },
      {
        path: 'customers',
        children: [
          // { path: 'cards', element: <CustomerCardsPage /> },
          { element: <CustomerListPage />, index: true },
          { path: ':id/account', element: <CustomerAccountPage /> },
          // { path: 'new', element: <CustomerCreatePage /> },
          // { path: ':id/edit', element: <CustomerEditPage /> },
        ],
      },
      {
        path: 'business',
        children: [
          { element: <BusinessListPage />, index: true },
          // { path: 'profile', element: <BusinessProfilePage /> },
          // { path: 'cards', element: <BusinessCardsPage /> },
          // { path: 'list', element: <BusinessListPage /> },
          // { path: 'new', element: <BusinessCreatePage /> },
          // { path: ':id/edit', element: <BusinessEditPage /> },
        ],
      },
      {
        path: 'deleted-customers',
        children: [
          { element: <DeletedCustomerListPage />, index: true },
          // { path: 'profile', element: <BusinessProfilePage /> },
          // { path: 'cards', element: <BusinessCardsPage /> },
          // { path: 'list', element: <BusinessListPage /> },
          // { path: 'new', element: <BusinessCreatePage /> },
          // { path: ':id/edit', element: <BusinessEditPage /> },
        ],
      },
      {
        path: 'transfer',
        children: [
          { element: <TransferListPage />, index: true },
          { element: <TransferProfilePage />, index: true },
          // { path: 'profile', element: <TransferProfilePage /> },
          // { path: 'cards', element: <TransferCardsPage /> },
          // { path: 'new', element: <TransferCreatePage /> },
          // { path: ':id/edit', element: <TransferEditPage /> },
        ],
      },
      {
        path: 'exchange-rate',
        children: [
          { element: <ExchangeRatePage />, index: true },
        ],
      },
      {
        path: 'about-us',
        children: [
          { element: <AboutUsPage />, index: true },
        ],
      },
      {
        path: 'privacy-policy',
        children: [
          { element: <PrivacyPage />, index: true },
        ],
      },
      {
        path: 'terms',
        children: [
          { element: <TermsPage />, index: true },
        ],
      },
      {
        path: 'contact-us',
        children: [
          { element: <ContactPage />, index: true },
        ],
      },
    ],
  },
];
