import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';


export default function BasicSelect({ title, cc, value, onChange }) {

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{title}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label="Age"
                    onChange={onChange}
                >
                    {cc?.map((c, idx) => (
                        <MenuItem key={idx} value={c.code}>{c.code} ({c.countries[0]})</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

BasicSelect.propTypes = {
    title: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    cc: PropTypes.array,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};