import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, TextField } from '@mui/material';
import Dropdown from "../common/Dropdown";
import cc from 'currency-codes/data';
import { toast } from 'react-toastify';
import { useAddNewCurrencyMutation, useUpdateCurrencyMutation } from 'src/store/Reducer/currency';
import { LoadingButton } from '@mui/lab';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const AddExchangeRateModal = ({ open, handleClose, setOpen, isEdit, selectedCurrency, refetch }) => {
    const [fromCurrency, setFromCurrency] = useState('USD');
    const [toCurrency, setToCurrency] = useState('EUR');
    const [fromAmount, setFromAmount] = useState('');

    const [addNewCurrencyData, { isLoading }] = useAddNewCurrencyMutation();
    const [updateCurrency, { isLoading: loading }] = useUpdateCurrencyMutation();

    useEffect(() => {
        if (isEdit && selectedCurrency) {
            setFromCurrency(selectedCurrency.from);
            setToCurrency(selectedCurrency.to);
            setFromAmount(selectedCurrency.value);
        } else {
            setFromCurrency('USD');
            setToCurrency('EUR');
            setFromAmount('');
        }
    }, [isEdit, selectedCurrency]);

    const handleInputChange = (event, setter) => {
        setter(event.target.value);
    };

    const handleSubmitCurrency = async () => {
        if (!fromCurrency || !toCurrency || !fromAmount) {
            toast.warn("Please select Currency or enter Amount");
            return;
        }

        try {
            if (isEdit) {
                await updateCurrency({
                    id: selectedCurrency._id,
                    from: fromCurrency,
                    to: toCurrency,
                    value: fromAmount
                });
                toast.success("Currency updated successfully!");
            } else {
                const { data } = await addNewCurrencyData({
                    from: fromCurrency,
                    to: toCurrency,
                    value: fromAmount
                });
                if (data) {
                    toast.success("Currency created successfully!");
                } else {
                    toast.error("Currency already exists");
                }
            }
            setFromCurrency('USD');
            setToCurrency('EUR');
            setFromAmount('');
            setOpen(false);
            await refetch();
        } catch (error) {
            toast.error(error.data?.message || error.message);
            console.error('Error fetching conversion rate:', error.message);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ textAlign: "center", marginBottom: '20px' }}>
                    <h3 style={{ marginBottom: 0, marginTop: 0 }}>{isEdit ? "Edit Currency" : "Add Currency"}</h3>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: "20px" }}>
                    <Dropdown title='From' cc={cc} value={fromCurrency} onChange={(e) => setFromCurrency(e.target.value)} />
                    <Dropdown title='To' cc={cc} value={toCurrency} onChange={(e) => setToCurrency(e.target.value)} />
                    <TextField
                        id="outlined-basic"
                        label="Value"
                        variant="outlined"
                        value={fromAmount}
                        onChange={(e) => handleInputChange(e, setFromAmount)}
                    />
                    <LoadingButton variant="contained" onClick={handleSubmitCurrency} loading={isLoading || loading}>
                        {isEdit ? "Update" : "Add"}
                    </LoadingButton>
                </Box>
            </Box>
        </Modal>
    );
}

export default AddExchangeRateModal;
