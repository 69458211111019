import { useMemo } from 'react';
import { paths } from 'src/routes/paths';
import { useLocales } from 'src/locales';
import SvgColor from 'src/components/svg-color';

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  deletedCustomers: icon('ic_deletedCustomers'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  exchangeRate: icon('ic_exchange_rate'),
  transfer: icon('ic_transfer'),
};

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      {
        subheader: t('overview'),
        items: [
          {
            title: t('Dashboard'),
            path: paths.adminDashboard.root,
            icon: ICONS.dashboard,
          },
          {
            title: t('Customers'),
            path: paths.adminDashboard.customer.root,
            icon: ICONS.user,

          },
          // {
          //   title: t('Business'),
          //   path: paths.adminDashboard.business.root,
          //   icon: ICONS.banking,
          // },
          {
            title: t('Deleted Customers'),
            path: paths.adminDashboard.deletedCustomers.root,
            icon: ICONS.deletedCustomers,
          },
          {
            title: t('Transfer Requests'),
            path: paths.adminDashboard.transfer.root,
            icon: ICONS.transfer,
          },
          {
            title: t('Exchange Rate'),
            path: paths.adminDashboard.exchangeRate.root,
            icon: ICONS.booking,
          },
          {
            title: t('FAQs'),
            path: paths.adminDashboard.faqs.root,
            icon: ICONS.menuItem,
          },
          {
            title: t('About Us'),
            path: paths.adminDashboard.aboutUs.root,
            icon: ICONS.tour,
          },
          {
            title: t('Privacy Policy'),
            path: paths.adminDashboard.privacyPolicy.root,
            icon: ICONS.lock,
          },
          {
            title: t('Terms & Conditions'),
            path: paths.adminDashboard.terms.root,
            icon: ICONS.kanban,
          },
          // Contact Us
          {
            title: t('Contact Us'),
            path: paths.adminDashboard.contactUs.root,
            icon: ICONS.chat,
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
